import React, { useCallback, useEffect, useRef, useState } from "react";
import "leaflet/dist/leaflet.css";
import "./index.css";
import L, { circleMarker } from "leaflet";
import "leaflet.heat";
import { addressPoints } from "./addressPoints";
import { MapContainer } from "react-leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import Marker from "../src/marker.png";
import Marker2 from "../src/marker2.png";
import Marker3 from "../src/marker3.png";
import Marker4 from "../src/marker4.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import axios from "axios";
import { json } from "react-router";

export default function Map() {
  const mapContainer = useRef();
  const [map, setMap] = useState({});
  const [points, setpoints] = useState();
  const [farmerData, setfarmerdata] = useState();
  const [farmerpolyData, setfarmerpolydata] = useState();
  let ddata = [];
  let fdata = [],fdataone=[];
  let polydata = [],
    polys = [];
  let fkharifcrop = [],
    fkharifpractice = [],
    frabicrop = [],
    frabipractice = [];
  var heat, poly;
  const chunk = (str) => {
    return str
      .split(",")
      .map(parseFloat)
      .reduce(
        (acc, val, i, arr) => (i % 2 ? acc : [...acc, arr.slice(i, i + 2)]),
        []
      );
  };
  const mapdata = (e) => {
    let fkharifcro = [];
    for (var i = 2017; i <= 2022; i++) {
      if (e[0].kharif != null) {
        console.log(
          i.toString() + "_" + "Crop" + e[0].kharif[i.toString() + "_" + "Crop"]
        );
        fkharifcro.push(
          "<span style=word-break:break-all>"+i.toString()+"</span>" +
            "Crop : " +
            e[0].kharif[i.toString() + "_" + "Crop"] +
            "<br/>"+
            "Practice : " +
            e[0].rabi[i.toString() + "_" + "Practice"] +
            "<br/>"
        );
      }
    }

    return fkharifcro;
  };
  const mapdatarabi = (e) => {
    let fkharifcro = [];
    for (var i = 2017; i <= 2022; i++) {
      if (e[0].rabi != null) {
        console.log(
          i.toString() + "_" + "Crop" + e[0].rabi[i.toString() + "_" + "Crop"]
        );
        fkharifcro.push(
          "<span style=word-break:break-all>"+i.toString()+"</span>" 
   +
            "Crop : " +
            e[0].rabi[i.toString() + "_" + "Crop"] +
            "<br/>"+
            "Practice : " +
            e[0].rabi[i.toString() + "_" + "Practice"] +
            "<br/>"
        );
      }
    }

    return fkharifcro;
  };
  const mapdatarabione = (e) => {
    let fkharifcro = [];
    for (var i = 2017; i <= 2022; i++) {
      if (e[0].rabi != null) {
        console.log(
          i.toString() +
            "_" +
            "Practice" +
            e[0].rabi[i.toString() + "_" + "Practice"]
        );
        fkharifcro.push(
          i.toString() +
            "_" +
            "Practice : " +
            e[0].rabi[i.toString() + "_" + "Practice"] +
            "<br/>"
        );
      }
    }
    return fkharifcro;
  };
  const mapdataone = (e) => {
    let fkharifcro = [];
    for (var i = 2017; i <= 2022; i++) {
      if (e[0].kharif != null) {
        console.log(
          i.toString() +
            "_" +
            "Practice" +
            e[0].kharif[i.toString() + "_" + "Practice"]
        );
        fkharifcro.push(
          i.toString() +
            "_" +
            "Practice : " +
            e[0].kharif[i.toString() + "_" + "Practice"] +
            "<br/>"
        );
      }
    }

    return fkharifcro;
  };
  async function fetchdata() {
    const client = axios.create({
    // baseURL: "http://localhost:3000/", //staging
     baseURL: "https://lowcarbonrice.growindigo.co.in/test", //production
    });

    client
      .get("api/Hitmap/GetHitMapCoordinates")

      .then(async (response) => {
        console.log("sjsjjsjsjsjs");

        ddata = [response.data.data];
        const myArray = response.data.data.split(" ", 3);
        console.log(
          JSON.parse(response.data.data).map((e) => {
            return e;
          })
        );
        console.log("sjsjjsjsjsjs");
        setpoints(response.data.data);
        heat = heat = L.heatLayer(
          JSON.parse(response.data.data)
            ? JSON.parse(response.data.data).map((p) => {
                return [p[0], p[1]];
              })
            : [],
          {
            radius: 25,
            blur: 0,
            gradient: { 1: "red" },
          }
        );

        console.log("respnose");
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  let img1=[],img2=[];
  async function fetchfarmerdata() {
    const client = axios.create({
     // baseURL: "http://localhost:3000/", //staging
      baseURL: "https://lowcarbonrice.growindigo.co.in/test", //production
    });

    client
      .get("api/Hitmap/GetFarmerDetails")

      .then((response) => {
     
        fdata = response.data.data.farmerData;
       
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function fetchfarmerpoly() {
    const client = axios.create({
    //  baseURL: "http://localhost:3000/", //staging
     baseURL: "https://lowcarbonrice.growindigo.co.in/test", //production
    });

    client
      .get("api/Hitmap/GetTaggingAndSurveyDetails")

      .then((response) => {
        console.log("polydata");

        polydata = response.data.data.tagAndSurveyData;

        polydata.map((d) =>
          fdata.map((i) => {
            console.log(
              i.farmerid.toString() +
                "DKJD" +
                JSON.stringify(d[i.farmerid.toString()])
            );
            polys.push(d[i.farmerid.toString()]);
          })
        );
        polys.map((e) => {
          console.log("yfyftfyufyfyfyfyfyyf");

          for (var i = 2017; i <= 2022; i++) {
            if (e[0].kharif != null) {
              console.log(
                i.toString() +
                  "_" +
                  "Crop" +
                  e[0].kharif[i.toString() + "_" + "Crop"]
              );
              fkharifcrop.push(
                i.toString() +
                  "_" +
                  "Crop : " +
                  e[0].kharif[i.toString() + "_" + "Crop"] +
                  "<br/>"
              );
            }
          }
        });

        const tempObj = {};
        const next = Object.values(tempObj);
        console.log("yfyftfyufyfyfyfyfyyf");
        console.log(fkharifcrop);
        console.log("yfyftfyufyfyfyfyfyyf");
        var arr = [];

        console.log("polydata");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  var marker;
  useEffect(() => {
    fetchdata();
    fetchfarmerdata();
    fetchfarmerpoly();

    const map = L.map(mapContainer.current, {
      attributionControl: false,
    }).setView([29.546735275918333, 76.98764701867694], 7);
    // var mapLink = '<a href="http://www.esri.com/">Esri</a>';
    // var wholink = 'i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community';

    L.tileLayer("http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", {
      maxZoom: 18,
      minZoom: 9,
      id: "mapbox/streets-v12",
      tileSize: 512,
      zoomOffset: -1,
      subdomains: ["mt0", "mt1", "mt2", "mt3"],
    }).addTo(map);

    var latlngs = [
      [29.546735275918333, 76.98764701867694],
      [29.557172297795002, 77.05415954100197],
      [29.574514534324408, 77.0616315677762],
      [29.607847492153446, 77.05781487814558],
      [29.61299476676256, 77.06557470392075],
      [29.583182485823816, 77.05988212882512],
      [29.527812482682993, 77.03624917212039],
      [29.540869289100268, 77.02772093043411],
      [29.54414724237913, 77.0363256171173],
    ];
    var options = { bordercolor: "yellow" };
 let DefaultIcon = L.icon({
      iconUrl: icon,
      shadowUrl: iconShadow,
    });
    L.Marker.prototype.options.icon = DefaultIcon;

    var marker;

    map.on("zoomend", function () {
      if (map.getZoom() < 9) {
        map.removeLayer(heat);
      } else {
        map.addLayer(heat);
      }
    });

    map.on("zoomend", function () {
      fdata.map((i,index) => {
        var ik=0;
       // const array = [Marker,Marker2,Marker3,Marker4,Marker,Marker2,Marker3,Marker4,Marker,Marker2,Marker3,Marker4]
        // if(ik>1)
        // {
        //   ik=0
        // }
        // else{
        //   ik=ik+1;
        // }
      
   
       
     
console.log('jhfhfhf')
console.log(fdata.length)
console.log('jhfhfhf')

        marker = L.marker(i.coordinate).bindPopup(
          `<div class="px10"><div class="inlineBx"><b> Farmer ID : </b>${i?.farmerid}</div><div class="inlineBx"><b> Village : </b>${i?.village}</div><div class="inlineBx"><b> Taluka : </b>${i?.taluka}</div><div class="inlineBx"><b> District : </b>${i?.district}</div><div class="inlineBx"><b> Stata : </b>${i?.state}</div></div>`
        );
     
       if (map.getZoom() > 12)
      {
        map.addLayer(marker);
      }
else      {
        map.removeLayer(marker);
      }
   
     
      });
     
      // if (map.getZoom() < 13 && map.getZoom() > 9) {
      //   map.addLayer(heat);
      //   fdata.map((i) => {
      //     marker = L.marker(i.coordinate).bindPopup(
      //       `<div><b> Name : </b>${i?.farmerName}</div><div><b> Village : </b>${i?.village}</div><div><b> Taluka : </b>${i?.taluka}</div><div><b> District : </b>${i?.district}</div><div><b> Stata : </b>${i?.state}</div>`
      //     );
      //     map.addLayer( L.marker(i.coordinate).bindPopup(
      //       `<div><b> Name : </b>${i?.farmerName}</div><div><b> Village : </b>${i?.village}</div><div><b> Taluka : </b>${i?.taluka}</div><div><b> District : </b>${i?.district}</div><div><b> Stata : </b>${i?.state}</div>`
      //     ));
      //   });
      // } else if (map.getZoom() > 12) {
      //   map.removeLayer(heat);
      //   fdata.map((i) => {
      //     marker = L.marker(i.coordinate).bindPopup(
      //       `<div class="inlineBx"><b> Name : </b>${i?.farmerName}</div><div class="inlineBx"><b> Village : </b>${i?.village}</div><div class="inlineBx"><b> Taluka : </b>${i?.taluka}</div><div class="inlineBx"><b> District : </b>${i?.district}</div><div class="inlineBx"><b> Stata : </b>${i?.state}</div>`
      //     );
      //   map.addLayer(marker);
      //   });
      // }
    });
    map.on('zoomlevelschange',function(){
     
    })
  var i=0;
    // map.on("zoomend", function () {
    //   if (map.getZoom() < 13) {
    //     // map.addLayer(heat);
    //     map.removeLayer(marker);
     
    //   }
    // });
    // var options = {
    //   radius: 8,
    //   fillColor: "yellow",
    //   color: "yellow",
    //   weight: 0,
    //   opacity: 1,
    //   fillOpacity: 0.3,
    // };

    map.on("zoomend", function () {
      if (map.getZoom() < 15 && map.getZoom() > 12) {
        polys.map((i) => {
         
          const arrayOfArrays = chunk(i[0].coordinates.toString());
          map.removeLayer(
            L.polygon(arrayOfArrays, options).bindPopup(i[0].fieldId)
          );
        });
      } else if (map.getZoom() > 14) {
        fdata.map((i) => {
          marker = L.marker(i.coordinate).bindPopup(
            `<div class="px10"><div><b> Name : </b>${i?.farmerName}</div><div><h1> Village : </h1>${i?.village}</div><div><h1> Taluka : </h1>${i?.taluka}</div><div><h1> District : </h1>${i?.district}</div><div><h1> Stata : </h1>${i?.state}</div></div>`
          );
          map.removeLayer(marker);
        });
        if(i==0)
       { polys.map((i) => {
         
          const arrayOfArrays = chunk(i[0].coordinates.toString());
          if (i[0].kharif == null) {
            map.addLayer(
              L.polygon(arrayOfArrays, options).bindPopup(
                // "<b>Field Id :</b>" +i[0].fieldId + "<b>Rabi :</b>" + JSON.stringify(i[0].rabi)

                `<div class="px10"><div class="pb"><b class="inlineCus">Field Id :</b>${i[0].fieldId}</div>
                <div class="pb"><b> Kharif : </b>${JSON.stringify(
                  i[0].rabi
                )}'</div></div>`
              )
            );
          } else {
            map.addLayer(
              L.polygon(arrayOfArrays, options).bindPopup(
                `<div class="px10" style=word-break:break-all><b class="inlineCus">Field Id :</b>${
                  i[0].fieldId
                }</div>
               <div class="dsd">
               <div class="col6" style=word-break:break-all><b> Kharif :</b>${mapdata(i)
                  ?.toString()
                  .replaceAll(",", "")}</div><div class="col6" style=word-break:break-all><b> Rabi :</b>${mapdatarabi(
                  i
                )
                  ?.toString()
                  .replaceAll(",", "")}</div>
                  </div>`
              )
            );
          }
        });}
        i=i+1;
      }
    });

    return () => map.remove();
  }, []);
  function random(mn, mx) {
    return Math.random() * (mx - mn) + mn;
}
  const center = [29.54414724237913, 77.0363256171173];
  return (
    <div
      style={{ padding: 0, margin: 0, width: "100%", height: "100vh" }}
      ref={(el) => (mapContainer.current = el)}
    ></div>
  );
}